import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

const tableHeight = 655;

export const MainContainer = styled(Box)({
    display: 'flex',
  });
  
export const GridContainer = styled(Box)({
    width: '100%',
    marginLeft: 20,
  });

export const StyledDataGrid = styled(DataGrid)(() => ({
    height: tableHeight,
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#EFEFEF50',
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: '#EFEFEF50',
    },
    '& .MuiDataGrid-columnHeader': {
    paddingLeft: '18px',
  },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid #c0c5cc',
  },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-colCellWrapper': {
      display: 'none',
    },
    '& .MuiDataGrid-row:hover': {
    backgroundColor: '#EFEFEF50',
  },
    marginBottom: '1.5em',
    cursor: 'pointer',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
  }));

  export const STATUS_COLORS = {
    READY: '#6DDE6A',
    ERROR: '#DA1E28'
  };
