import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import React from 'react';
import ResourcesAPI from '../../api/resources/Resources';
import SignOn from '../admin/signOn';
import SideNav from '../sideNav/SideNav';
import TopBar from '../topbar/TopBar';

const apiHandle = new ResourcesAPI();

const MainContainerStyled = styled(Container)(() => ({
  width: '80%',
  top: '72px',
  left: 210,
  position: 'absolute',
}));

export default function MainContainer({ Component, pageProps }) {
  return (
    <>
      <TopBar api={apiHandle} />
      <SignOn>
        <SideNav />
        <MainContainerStyled maxWidth='xl' disableGutters >
          <Component {...pageProps} api={apiHandle} />
        </MainContainerStyled>
      </SignOn>
    </>
  );
}
